import React from "react";
import styled from "styled-components";
import { CpLoader, CpTooltip } from "canopy-styleguide!sofe";
import { isEmpty, debounce, lowerCase } from "lodash";
import { search } from "src/resources/search.resource.js";
import { catchAsyncStacktrace } from "auto-trace";
import {
  getQuestionLink,
  getSourceFormLink,
  getTaxFormLink,
  getSharedValuesLink,
  getSectionLink,
} from "src/versions/change.helpers.js";

const MIN_QUERY_LENGTH = 1;

export default class Search extends React.Component {
  state = {
    query: "",
  };

  constructor() {
    super();
    this.debouncedSearch = debounce(this.search, 500);
  }

  componentDidMount() {
    if (this.queryInput) {
      this.queryInput.focus();
    }
  }

  render() {
    return (
      <div className="cps-modal">
        <div className="cps-modal__screen" />
        <div className="cps-modal__dialog cps-card">
          {this.searchBar()}
          {!isEmpty(this.state.query) && this.results()}
        </div>
      </div>
    );
  }

  searchBar = () => {
    return (
      <StyledSearch className="searchBar">
        <span
          className="cps-icon cps-icon-search"
          style={{ margin: "0 8px" }}
        />
        <div className="cps-form-group-resize" style={{ width: "100%" }}>
          <input
            ref={(ref) => (this.queryInput = ref)}
            type="text"
            className="cps-form-control-resize input"
            placeholder="Start typing..."
            value={this.state.query}
            onChange={this.handleQueryChange}
          />
        </div>
        <span
          className="cps-icon cps-icon-close"
          style={{ margin: "0 8px", cursor: "pointer" }}
          onClick={this.props.close}
        />
      </StyledSearch>
    );
  };

  results = () => {
    return isEmpty(this.state.results) ? (
      <StyledResultsRow className="resultsRow">
        {this.state.searching ? <CpLoader /> : <span>No results</span>}
      </StyledResultsRow>
    ) : (
      <StyledResultsWrapper>
        {this.state.results.map(this.resultRow)}
      </StyledResultsWrapper>
    );
  };

  resultRow = (result) => {
    const versionId = this.props.urlVersionBase;
    const version = { id: versionId ? versionId.split("-")[0] : "" };
    const revision = { id: versionId ? versionId.split("-")[1] : "" };

    let link = "";
    let icon = "";

    switch (result.type) {
      case "question":
        link = getQuestionLink(version, revision, result);
        icon = "help";
        break;
      case "source-form":
        link = getSourceFormLink(version, revision, result);
        icon = "taxes";
        break;
      case "section":
        link = getSectionLink(version, revision, result);
        icon = "tile-view";
        break;
      case "tax-form":
        link = getTaxFormLink(version, revision, result);
        icon = "work";
        break;
      case "shared-value":
        link = getSharedValuesLink(version, revision, { key: result.name });
        icon = "list";
        break;
    }

    return (
      <StyledResultsRow key={result.type + result.id} className="resultsRow">
        <a href={link} onClick={this.props.close}>
          <CpTooltip text={lowerCase(result.type)}>
            <div className="cp-mr-12">
              <span className={`cps-icon cps-icon-${icon}`} />
            </div>
          </CpTooltip>
          {result.name}
        </a>
      </StyledResultsRow>
    );
  };

  handleQueryChange = (e) => {
    this.setState(
      {
        query: e.target.value,
      },
      (query) => this.debouncedSearch(query, this.props.urlVersionBase)
    );
  };

  search = () => {
    if (this.state.query.length >= MIN_QUERY_LENGTH) {
      this.setState({
        searching: true,
      });

      search(this.state.query, this.props.urlVersionBase).subscribe(
        (results) => {
          this.setState({ results, searching: false });
        },
        catchAsyncStacktrace()
      );
    }
  };
}

const StyledSearch = styled.div`
  &.searchBar {
    display: flex;
    align-items: center;
    .input {
      font-size: 24px;
      line-height: 40px;
    }
    .input:focus {
      border: 1px solid transparent;
    }
  }
`;

const StyledResultsRow = styled.div`
  &.resultsRow {
    min-height: 38px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    border-top: 1px solid #dfdfdf;
    text-decoration: none;
    color: #333;
  }

  &.resultsRow:hover {
    background: #dfdfdf;
    text-decoration: none;
  }
`;

const StyledResultsWrapper = styled.div`
  max-height: 650px;
  overflow: auto;
`;
